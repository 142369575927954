<template>
  <div>
    <h1 class="h1 text-center mt-5 mb-3 font-weight-bolder">
      Fun, Fast &amp; Simple
    </h1>
    <b-row align-h="center" class="mt-5 overflow-hidden howitworks">
      <b-col cols="12" xl="8" lg="12">
        <div class="d-flex" v-touch:swipe="swipeEvent">
          <div class="slider-image">
            <img
              v-for="(slide, index) in slides"
              :key="index"
              :src="slide.image"
              alt="How ScoreMaster® Works"
              :class="{ active: currSlide === index + 1 }"
            />
          </div>
          <div class="slider-content">
            <div class="slides">
              <div
                v-for="(slide, index) in slides"
                :key="index"
                class="box-shadow bg-white slide"
                :class="{ active: currSlide == index + 1 }"
              >
                <p
                  class="h3 mb-4 font-weight-bold mt-3 mb-3"
                  v-html="slide.title"
                />
                <p class="mb-4" v-html="slide.description" />
                <ArrowBtn
                  class="icon icon-info mb-3 arrow-btn d-none d-lg-block"
                  style="width: 30px;"
                  @click="nextSlide"
                />
              </div>
            </div>
            <div class="slider-nav">
              <div
                v-for="n in slides.length"
                :key="n"
                class="dot"
                :class="{ active: currSlide === n }"
                @click="currSlide = n"
              />
            </div>
            <SignupLinkButton
              v-if="landingPage"
              :to="{ name: 'IndividualSignup' }"
              pill
              variant="secondary"
              class="w-100 mt-4"
            >
              {{ ctaText }}
            </SignupLinkButton>
            <b-button
              v-else
              :to="{ name: 'IndividualBenefits' }"
              pill
              size="lg"
              variant="secondary"
              class="w-100 text-center"
            >
              {{ ctaText }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ArrowBtn from '@/assets/svg/icon_btnArrow.svg'

export default {
  name: 'FunFastSimpleSlider',
  components: {
    ArrowBtn,
  },
  props: {
    ctaText: {
      type: String,
      default: 'See Benefits',
    },
  },
  data() {
    return {
      currSlide: 1,
      slides: [
        {
          image: require('@/assets/images/howitworks_seepoints_phone1.jpg'),
          title: 'How Many Credit Score Points Can You Get?',
          description:
            "Enroll in 2 minutes. Then use the ScoreMaster<sup>&reg;</sup> 'Move Me' slider to see how many points you can get. Also, see how many points you can lose by spending.",
        },
        {
          image: require('@/assets/images/howitworks_setplan_phone2.jpg'),
          title: 'Save Your Plan',
          description:
            'ScoreMaster<sup>&reg;</sup>  will save your Plan, so you can leave and come back. Your Plan will detail precisely which accounts to pay, including how much and when.',
        },
        {
          image: require('@/assets/images/howitworks_shareplan_phone3.jpg'),
          title: 'You Can Share in Real-Time',
          description:
            'Easily share access to your ScoreMaster<sup>&reg;</sup> Plan with your lender to discuss options and better deals.',
        },
        {
          image: require('@/assets/images/howitworks_spending_phone4.jpg'),
          title: 'Protect Your Score When Spending',
          description:
            'ScoreMaster<sup>&reg;</sup> will show you which cards to use for your spending and by how much. Then see precisely when to pay the cards back to avoid hurting your credit score.',
        },
      ],
    }
  },
  methods: {
    nextSlide() {
      if (this.currSlide < this.slides.length) this.currSlide++
      else this.currSlide = 1
    },
    prevSlide() {
      if (this.currSlide !== 1) this.currSlide--
      else this.currSlide = this.slides.length
    },
    swipeEvent(direction) {
      if (direction === 'left') {
        this.nextSlide()
      }
      if (direction === 'right') {
        this.prevSlide()
      }
    },
  },
  computed: {
    landingPage: function() {
      return this.$route.name === 'LandingPage'
    },
  },
}
</script>
