<template>
  <div>
    <b-container class="pt-3 features">
      <b-row align-h="center" class="mt-lg-5 pt-3">
        <b-col cols="12" md="8">
          <div class="alternating-blocks">
            <div class="block">
              <p
                class="h2 font-weight-bolder mb-4 mt-3 d-block d-md-none mobile-title"
              >
                Using ScoreMaster<sup>&reg;</sup>
              </p>
              <div class="block-image">
                <b-img
                  src="@/assets/images/features_scoremaster_phone.jpg"
                  alt="ScoreMaster® credit score monitoring tool"
                  fluid
                  class="d-none d-md-block"
                />
                <b-img
                  v-for="(slide, index) in slides.scoremaster"
                  :key="index"
                  :src="slide.image"
                  alt="ScoreMaster® credit score app"
                  fluid
                  class="d-md-none"
                  v-show="currentSlide.scoremaster === index + 1"
                  v-touch:swipe="swipeEvent('scoremaster')"
                />
              </div>
              <div class="block-content">
                <p class="h2 font-weight-bolder mb-4 d-none d-md-block">
                  Using ScoreBoost<sup>&trade;</sup>
                </p>
                <div class="slider-nav">
                  <div
                    v-for="n in slides.scoremaster.length"
                    :key="n"
                    class="dot"
                    :class="{ active: currentSlide.scoremaster === n }"
                    @click="currentSlide.scoremaster = n"
                  />
                </div>
                <div class="slider" v-touch:swipe="swipeEvent('scoremaster')">
                  <div
                    v-for="(slide, index) in slides.scoremaster"
                    class="slide"
                    :key="index"
                    v-show="currentSlide.scoremaster === index + 1 || !isMobile"
                  >
                    <p
                      class="h5 font-weight-bold mb-3"
                      v-html="slide.title"
                    ></p>
                    <p v-html="slide.description"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="block image-right">
              <p
                class="h2 font-weight-bolder mb-4 mt-3 d-block d-md-none mobile-title"
              >
                Using ScoreBuilder<sup>&reg;</sup>
              </p>
              <div class="block-image">
                <b-img
                  src="@/assets/images/features_scorebuilder_phone.jpg"
                  alt="credit score builder and monitoring tool"
                  fluid
                  class="d-none d-md-block"
                />
                <b-img
                  v-for="(slide, index) in slides.scorebuilder"
                  :key="index"
                  :src="slide.image"
                  alt="credit score builder and monitoring app"
                  fluid
                  class="d-md-none"
                  v-show="currentSlide.scorebuilder === index + 1"
                  v-touch:swipe="swipeEvent('scorebuilder')"
                />
              </div>
              <div class="block-content">
                <p class="h2 font-weight-bolder mb-4 d-none d-md-block">
                  Using ScoreBuilder<sup>&reg;</sup>
                </p>
                <div class="slider-nav">
                  <div
                    v-for="n in slides.scorebuilder.length"
                    :key="n"
                    class="dot"
                    :class="{ active: currentSlide.scorebuilder === n }"
                    @click="currentSlide.scorebuilder = n"
                  />
                </div>
                <div class="slider" v-touch:swipe="swipeEvent('scorebuilder')">
                  <div
                    v-for="(slide, index) in slides.scorebuilder"
                    class="slide"
                    :key="index"
                    v-show="
                      currentSlide.scorebuilder === index + 1 || !isMobile
                    "
                  >
                    <p
                      class="h5 font-weight-bold mb-3"
                      v-html="slide.title"
                    ></p>
                    <p v-html="slide.description"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="block">
              <p
                class="h2 font-weight-bolder mb-4 mt-3 d-block d-md-none mobile-title"
              >
                Using PrivacyMaster<sup>&reg;</sup>
              </p>
              <div class="block-image">
                <b-img
                  src="@/assets/images/features_privacymaster_phone.jpg"
                  alt="credit score tracker"
                  fluid
                  class="d-none d-md-block"
                />
                <b-img
                  v-for="(slide, index) in slides.privacymaster"
                  :key="index"
                  :src="slide.image"
                  alt="credit score tracker for mobile"
                  fluid
                  class="d-md-none"
                  v-show="currentSlide.privacymaster === index + 1"
                  v-touch:swipe="swipeEvent('privacymaster')"
                />
              </div>
              <div class="block-content align-self-center">
                <p class="h2 font-weight-bolder mb-4 d-none d-md-block">
                  Using PrivacyMaster<sup>&reg;</sup>
                </p>
                <div class="slider-nav">
                  <div
                    v-for="n in slides.privacymaster.length"
                    :key="n"
                    class="dot"
                    :class="{ active: currentSlide.privacymaster === n }"
                    @click="currentSlide.privacymaster = n"
                  />
                </div>
                <div class="slider" v-touch:swipe="swipeEvent('privacymaster')">
                  <div
                    v-for="(slide, index) in slides.privacymaster"
                    class="slide"
                    :key="index"
                    v-show="
                      currentSlide.privacymaster === index + 1 || !isMobile
                    "
                  >
                    <p
                      class="h5 font-weight-bold mb-3"
                      v-html="slide.title"
                    ></p>
                    <p v-html="slide.description"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" lg="4" class="text-center">
          <SignupLinkButton
            :to="{ name: 'IndividualSignup' }"
            pill
            variant="secondary"
            class="w-100 mt-5"
            >Sign Up Now!</SignupLinkButton
          >
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="bg-gray-100 mt-5">
      <b-container>
        <p class="h1 mb-5 pt-5 font-weight-bolder text-center">
          Additional Features
        </p>
        <b-row align-h="center" class="feature-grid">
          <b-media class="mb-4 col-lg-2 col-12">
            <template v-slot:aside>
              <IconSCR style="height:60px;" class="icon icon-primary" />
            </template>
            <p class="font-weight-semibold">
              Smart Credit Report<sup>&reg;</sup>
            </p>
            <p>
              A simple and innovative way to view your credit report. Use Action
              buttons to quickly ask questions and resolve problems.
            </p>
          </b-media>
          <b-media class="mb-4 col-lg-2 col-12 offset-lg-1">
            <template v-slot:aside>
              <IconMM style="height:60px;" class="icon icon-primary" />
            </template>
            <p class="font-weight-semibold">Money Manager</p>
            <p>
              All your online banking in one place. Use Action buttons to
              quickly ask questions and resolve problems.
            </p>
          </b-media>
          <b-media class="mb-4 col-lg-2 col-12 offset-lg-1">
            <template v-slot:aside>
              <IconFI style="height:60px;" class="icon icon-primary" />
            </template>
            <p class="font-weight-semibold">$1 Million Fraud Insurance</p>
            <p>
              Covers Bank, Savings, Brokerage, Lines of Credit, Credit Card and
              more for your whole family.
              <br />
              <br />
              <em><small>Activation required after enrollment.</small></em>
            </p>
          </b-media>
        </b-row>
        <b-row align-h="start" class="feature-grid">
          <b-media class="mb-4 col-lg-2 col-12 offset-lg-2">
            <template v-slot:aside>
              <IconAlerts style="height:60px;" class="icon icon-primary" />
            </template>
            <p class="font-weight-semibold">Alerts</p>
            <p>
              Receive alerts for suspicious activity, payments due & credit
              monitoring events. Link your phone to get alerts faster.
            </p>
          </b-media>
          <b-media class="mb-4 col-lg-2 col-12 offset-lg-1">
            <template v-slot:aside>
              <IconAB style="height:60px;" class="icon icon-primary" />
            </template>
            <p class="font-weight-semibold">Action Buttons</p>
            <p>
              With a simple button you can remove identity theft, negotiate
              debts, resolve reporting problems and ask questions directly with
              your creditors.
            </p>
          </b-media>
          <b-media class="mb-4 col-lg-2 col-12 offset-lg-1">
            <template v-slot:aside>
              <Icon3BR style="height:60px;" class="icon icon-primary" />
            </template>
            <p class="font-weight-semibold">3B Report &amp; Scores</p>
            <p>
              View a side by side comparison of how your credit report looks
              across all 3 credit bureaus. Keep your reports free of any errors.
            </p>
          </b-media>
        </b-row>
      </b-container>
    </b-container>
    <b-container>
      <b-row align-h="center">
        <b-col cols="12" lg="4" class="text-center mt-5 mt-lg-6">
          <SignupLinkButton
            :to="{ name: 'IndividualSignup' }"
            pill
            variant="secondary"
            class="w-100 mt-4"
            >Get ScoreMaster<sup>&reg;</sup></SignupLinkButton
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import IconSCR from '@/assets/svg/icon_SCR.svg'
import IconMM from '@/assets/svg/icon_MM.svg'
import IconFI from '@/assets/svg/icon_FI.svg'
import Icon3BR from '@/assets/svg/icon_3BR.svg'
import IconAB from '@/assets/svg/icon_AB.svg'
import IconAlerts from '@/assets/svg/icon_alerts.svg'

export default {
  name: 'AlternatingFeatures',
  components: {
    IconSCR,
    IconMM,
    IconFI,
    Icon3BR,
    IconAB,
    IconAlerts,
  },
  data() {
    return {
      isMobile: window.innerWidth < 768,
      currentSlide: {
        scoremaster: 1,
        scorebuilder: 1,
        privacymaster: 1,
      },
      slides: {
        scoremaster: [
          {
            title: 'Pay Differently',
            description:
              'See how your payments can increase your credit score. Know how much to pay and by when. Know the optimal time to apply for a credit card, auto loan, mortgage or other credit.',
            image: require('@/assets/images/features_scoremaster_phone_mobile1.jpg'),
          },
          {
            title: 'Spending Impact',
            description:
              'See how your spending can lower your credit score. Balance the spending between your accounts and know the optimum time to repay.',
            image: require('@/assets/images/features_scoremaster_phone_mobile2.jpg'),
          },
          {
            title: 'A Personalized Plan to Get More Points',
            description:
              'Once your target score is saved, ScoreBoost<sup>&trade;</sup> will tell you specifically what to do.',
            image: require('@/assets/images/features_scoremaster_phone_mobile3.jpg'),
          },
          {
            title: 'Timing',
            description:
              'Your Plan includes &apos;Post Payment By&apos; dates targeted before a creditor reports your account details to credit reporting agencies.',
            image: require('@/assets/images/features_scoremaster_phone_mobile4.jpg'),
          },
        ],
        scorebuilder: [
          {
            title: 'A Personalized Plan to Get More Points',
            description:
              'ScoreBuilder<sup>&reg;</sup> creates a personalized plan to help you understand what is helping and hurting your credit score, plus what actions you can take.',
            image: require('@/assets/images/features_scorebuilder_phone_mobile1.jpg'),
          },
          {
            title: 'Simple Action Buttons',
            description:
              'View your negative accounts weighing down your score and take action to take care of them.',
            image: require('@/assets/images/features_scorebuilder_phone_mobile2.jpg'),
          },
        ],
        privacymaster: [
          {
            title: 'Protect Your Personal Information',
            description:
              'We scan for websites and data brokers that are selling or misusing your personal information and alert you when discovered. PrivacyMaster<sup>®</sup> allows you to take immediate action within our dashboard to get your information removed. We then monitor for their ongoing compliance.',
            image: require('@/assets/images/features_privacymaster_phone_mobile1.jpg'),
          },
        ],
      },
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768
    },
    swipeEvent(param) {
      let self = this

      return function(direction) {
        if (direction === 'left') {
          if (self.currentSlide[param] < self.slides[param].length)
            self.currentSlide[param]++
        }
        if (direction === 'right') {
          if (self.currentSlide[param] !== 1) self.currentSlide[param]--
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      let resizeEnd
      window.addEventListener('resize', () => {
        clearTimeout(resizeEnd)
        resizeEnd = setTimeout(() => {
          this.onResize()
        }, 100)
      })
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>

<style lang="scss">
.features .alternating-blocks > div:last-child .block {
  margin-bottom: 0;
}
</style>
